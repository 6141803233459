<!-- 商家端 - 查看主体信息 -->
<template>
  <div class="contractInfo">
      <div class="title">
        <strong class="form-title__text">查看主体信息</strong>
      </div>
      <!--主体信息-->
      <div class="form-title">
        <strong class="form-title__text">合同主体信息</strong>
      </div>
      <p class="tips-title">主体信息需要保证唯一性，不允许重复</p>
      <div class="wrap">
        <el-descriptions title="" direction="vertical" :column="4" border>
          <el-descriptions-item label="类型" label-class-name="label-head label-20" content-class-name="label-head">个人/企业</el-descriptions-item>
          <el-descriptions-item label="内容" label-class-name="label-head label-30" content-class-name="label-head">{{ contractInfoForm.merchantType == 'PERSONAL' ? '个人' : '公司' }}</el-descriptions-item>
          <el-descriptions-item label="类型" label-class-name="label-head label-20" content-class-name="label-head">
            <template>
              <template v-if="contractInfoForm.merchantType === 'ENTERPRISE'">
                公司类型
              </template>
              <template v-else>
                联系人真实姓名
              </template>
            </template>
          </el-descriptions-item>
          <el-descriptions-item label="内容" label-class-name="label-head label-30" content-class-name="label-head">
            <template>
              <template v-if="contractInfoForm.merchantType === 'ENTERPRISE'">
                {{ contractInfoForm.companyType == 'MAINLAND_COMPANY' ? '大陆企业' : '' }}
              </template>
              <template v-else>
                {{ contractInfoForm.contactRealName }}
              </template>
            </template>
          </el-descriptions-item>
          <template v-if="contractInfoForm.merchantType === 'ENTERPRISE'">
            <el-descriptions-item label="企业名称" label-class-name="label-box" content-class-name="label-head">法人姓名</el-descriptions-item>
            <el-descriptions-item :label="contractInfoForm.company" label-class-name="label-box" content-class-name="label-head">{{ contractInfoForm.contactRealName }}</el-descriptions-item>
            <el-descriptions-item label="税号" label-class-name="label-box" content-class-name="label-head">法人身份证号</el-descriptions-item>
            <el-descriptions-item :label="contractInfoForm.taxNo" label-class-name="label-box" content-class-name="label-head">{{ contractInfoForm.contactIdcardNo }}</el-descriptions-item>
            <el-descriptions-item label="法人联系电话" label-class-name="label-box" content-class-name="label-head">联系人邮箱</el-descriptions-item>
            <el-descriptions-item :label="contractInfoForm.contactPhone" label-class-name="label-box" content-class-name="label-head">{{ contractInfoForm.contactEmail }}</el-descriptions-item>
            <el-descriptions-item label="联系人地址" label-class-name="label-box" content-class-name="label-head">营业执照</el-descriptions-item>
            <el-descriptions-item :label="contractInfoForm.contactAddress" label-class-name="label-box" content-class-name="label-head">
              <el-button size="small" type="text" @click="openAttachment(contractInfoForm.attachmentJson)">查看</el-button>
            </el-descriptions-item>
          </template>
          <template v-else>
            <el-descriptions-item label="联系人身份证号码" label-class-name="label-box" content-class-name="label-head">联系人电话</el-descriptions-item>
            <el-descriptions-item :label="contractInfoForm.contactIdcardNo" label-class-name="label-box" content-class-name="label-head">{{ contractInfoForm.contactPhone }}</el-descriptions-item>
            <el-descriptions-item label="联系人邮箱" label-class-name="label-box" content-class-name="label-head">联系人地址</el-descriptions-item>
            <el-descriptions-item :label="contractInfoForm.contactEmail" label-class-name="label-box" content-class-name="label-head">{{ contractInfoForm.contactAddress }}</el-descriptions-item>
            <el-descriptions-item label="身份证照" label-class-name="label-box" content-class-name="label-head isHide"></el-descriptions-item>
            <el-descriptions-item :label="contractInfoForm.contactAddress" label-class-name="label-box" content-class-name="label-head isHide">
              <template slot="label">
                <el-button size="small" type="text" @click="openAttachment(contractInfoForm.attachmentJson)">查看</el-button>
              </template>
            </el-descriptions-item>
          </template>
        </el-descriptions>
      </div>
      <!-- 发票主体信息 -->
      <div class="form-title">
        <strong class="form-title__text">发票主体信息</strong>
      </div>
      <p class="tips-title tips-title--color">发票的主体必须跟合作的主体保持一致</p>
      <div class="wrap">
        <el-descriptions title="" direction="vertical" :column="4" border>
          <el-descriptions-item label="类型" label-class-name="label-head label-20" content-class-name="label-head">发票抬头</el-descriptions-item>
          <el-descriptions-item label="内容" label-class-name="label-head label-30" content-class-name="label-head">{{ invoiceInfoForm.title }}</el-descriptions-item>
          <el-descriptions-item label="类型" label-class-name="label-head label-20" content-class-name="label-head">
            <template v-if="contractInfoForm.merchantType === 'ENTERPRISE'">
              税号
            </template>
          </el-descriptions-item>
          <el-descriptions-item label="内容" label-class-name="label-head label-30" content-class-name="label-head">
            <template v-if="contractInfoForm.merchantType === 'ENTERPRISE'">
              {{ invoiceInfoForm.taxNo }}
            </template>
          </el-descriptions-item>
          <el-descriptions-item label="开户银行" label-class-name="label-box" content-class-name="label-head" v-if="contractInfoForm.merchantType === 'ENTERPRISE'">企业地址</el-descriptions-item>
          <el-descriptions-item :label="invoiceInfoForm.bankName" label-class-name="label-box" content-class-name="label-head" v-if="contractInfoForm.merchantType === 'ENTERPRISE'">{{ invoiceInfoForm.contactAddress }}</el-descriptions-item>
          <el-descriptions-item label="银行号账号" label-class-name="label-box" content-class-name="label-head" v-if="contractInfoForm.merchantType === 'ENTERPRISE'">企业电话</el-descriptions-item>
          <el-descriptions-item :label="invoiceInfoForm.bankAccount" label-class-name="label-box" content-class-name="label-head" v-if="contractInfoForm.merchantType === 'ENTERPRISE'">{{ invoiceInfoForm.mobile }}</el-descriptions-item>
          <el-descriptions-item label="发票联系人信息（用于发票邮寄和发送使用）" label-class-name="label-box tips-title--color" content-class-name="label-head isHide" :span="4"></el-descriptions-item>
          <el-descriptions-item label="联系人姓名" label-class-name="label-box" content-class-name="label-head">联系人电话</el-descriptions-item>
          <el-descriptions-item :label="invoiceInfoForm.contactName" label-class-name="label-box" content-class-name="label-head">{{ invoiceInfoForm.contactPhone }}</el-descriptions-item>
          <el-descriptions-item label="联系人地址" label-class-name="label-box" content-class-name="label-head">联系人邮箱</el-descriptions-item>
          <el-descriptions-item :label="invoiceInfoForm.contactAddressVO.provinceName + invoiceInfoForm.contactAddressVO.cityName + invoiceInfoForm.contactAddressVO.zoneName + invoiceInfoForm.contactAddressVO.addressDetail" label-class-name="label-box" content-class-name="label-head" v-if="invoiceInfoForm.contactAddressVO">{{ invoiceInfoForm.email }}</el-descriptions-item>    
        </el-descriptions>
      </div>
      <!-- 收付款账号信息 -->
      <div class="form-title">
        <strong class="form-title__text">收付款账号信息</strong>
      </div>
      <div class="wrap">
        <el-table
          :data="bankCardList"
          style="width: 100%"
          border
          :header-row-style="headerRowStyle"
          :header-cell-style="headerCellStyle"
        >
          <el-table-column label="户名" prop="cardholder"></el-table-column>
          <el-table-column label="开户行" prop="bankName"></el-table-column>
          <el-table-column label="账户" prop="cardNumber"></el-table-column>
          <el-table-column :render-header="renderHeader" label="联行号、(提现使用)" prop="bankCode"></el-table-column>
          <el-table-column min-width="110px" :render-header="renderHeader" label="开户行许可证/收款银行确认函、(提现使用)" prop="attachmentJsonList">
            <template slot-scope="{ row }">
              <el-button size="small" @click="openAttachment(row.attachmentJsonList)" type="text" v-if="row.attachmentJsonList.length">查看</el-button>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column label="是否可用">
            <template slot-scope="{ row }">{{ row.status === '1' ? '可用' : '不可用' }}</template>
          </el-table-column>
        </el-table>
      </div>
      <div slot="footer" class="dialog-footer">
      <el-button @click="backPage" size="medium">返 回</el-button>
    </div>
    <el-dialog :visible.sync="previewVisible" title="查看附件" width="50%">
      <el-carousel :autoplay="false" height="500px">
        <el-carousel-item :key="item" v-for="item in attachmentJsonList" class="carousel-item">
          <a v-if="isNotImgFile(item)" class="file-link" :href="item">{{item}}</a>
          <img :src="item" class="carousel-img" v-else/>
        </el-carousel-item>
      </el-carousel>
    </el-dialog>
  </div>
</template>

<script>
import { getContractInfo } from '@/api/subject-information';
export default {
  name: 'viewSubjectInformation',
  data() {
    return {
      bankCardList: [],
      contractInfoForm: {},
      invoiceInfoForm: {},
      headerRowStyle: { color: '#333333' },
      headerCellStyle: { backgroundColor: '#F3F3F3', fontWeight: '400', fontSize: '12px' },
      attachmentJsonList: [],
      previewVisible: false,
      whiteList: ['png', 'jpg', 'jpeg', 'gif', 'webp']
    };
  },
  created() {
    if (window.history && window.history.pushState) { 
      // 回退监听事件
      window.addEventListener('popstate', this.backPage, { once: true });
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    isNotImgFile(url) {
      if(!url){
        return true;
      }
      return this.whiteList.indexOf(url.substring(url.lastIndexOf('.') + 1)) === -1;
    },
    // 获取详情数据
    getData() {
      const id = this.$route.params.id;
      getContractInfo({
        id,
      }).then(res => {
        if(res.code === '0') {
          const { distributorContractInfoVO = {}, distributorBankCardVOList = {}, invoiceTitleVO = {} } = res.data;
          this.bankCardList = distributorBankCardVOList;
          this.contractInfoForm = distributorContractInfoVO;
          this.invoiceInfoForm = {
            title: distributorContractInfoVO.merchantType === 'ENTERPRISE' ? distributorContractInfoVO.company : distributorContractInfoVO.contactRealName,
            taxNo: distributorContractInfoVO.merchantType === 'ENTERPRISE' ? distributorContractInfoVO.taxNo : invoiceTitleVO.taxNo,
            ...invoiceTitleVO,
          };
        }
      });
    },
    // 查看附件
    openAttachment(list) {
      this.attachmentJsonList = list;
      this.previewVisible = true;
    },
    // 处理表头
    renderHeader(h, { column }) {
      const [label1, label2] = column.label.split('、');
      return h('div', {}, [h('p', {}, label1), h('p', {}, label2)]);
    },
    // 返回
    backPage() {
      this.$router.replace('/syoung/shop/detail?activeName=second');
    },
  }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
::v-deep {
  .el-table th > .cell {
    white-space: pre-line;
  }
  .label-box {
    background: #fff;
    color: #333;
  }
  .label-head {
    color: #333;
  }
  .label-20 {
    width: 20%;
  }
  .label-30 {
    width: 30%;
  }
  .isHide {
    display: none;
  }
  .tips-title--color {
    color: rgba(92, 90, 90, 0.67);
  }
}
.contractInfo {
  padding: 0 20px;
}

.custom-label {
  li {
    line-height: 20px;
  }
}
.title {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;
}
.tips-title {
  display: flex;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 30px;
  margin-left: 10px;
  &--color {
    color: rgba(92, 90, 90, 0.67);
  }
}
.form-title {
  display: flex;
  align-items: center;
  padding: 12px 0;
  margin-top: 20px;
  &__text {
    display: flex;
    align-items: center;
    min-width: 120px;
    font-size: 14px;
    color: var(--color-text-primary);
    font-weight: bold;
    &:before {
      content: '';
      height: 12px;
      width: 2px;
      background: var(--color-primary);
      margin-right: 4px;
    }
  }
}
.dialog-footer {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.wrap {
  width: 880px;
  box-sizing: content-box;
}
.ul {
  > li {
    margin-bottom: 10px;
    cursor: pointer;
    color: #AB0033;
    text-decoration: underline;
  }
}
.carousel-img {
  display: block;
  height: 100%;
  width: auto;
  margin: auto;
  object-fit: contain;
}
.file-link{
  color: #AB0033;
  text-decoration: underline;
}
.carousel-item {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
