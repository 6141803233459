import { withExtTenantIdRequest } from '@/utils/request';

// 新增主体信息时获取获取银行卡列表
export function getlistForApps() {
  return withExtTenantIdRequest({
    url: '/soyoungzg/api/distributorBankCard/listForApps',
    method: 'get',
  });
}

// 新增银行卡信息
export function createBankCard(data) {
  return withExtTenantIdRequest({
    url: '/soyoungzg/api/distributorBankCard/create',
    method: 'post',
    data,
  });
}

// 查看主体信息
export function getContractInfo(data) {
  const formData = new FormData();
  formData.append('id', data.id);
  return withExtTenantIdRequest({
    url: '/soyoungzg/api/distributorContractInfo/getContractInfo',
    method: 'post',
    data: formData,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
}

// 新增主体信息
export function createInfo(data) {
  return withExtTenantIdRequest({
    url: '/soyoungzg/api/distributorContractInfo/create',
    method: 'post',
    data,
  });
}

// 编辑主体信息
export function updateInfo(data) {
  return withExtTenantIdRequest({
    url: '/soyoungzg/api/distributorContractInfo/update',
    method: 'post',
    data,
  });
}

// 修改收付款账号信息
export function updateBankCard(data) {
  return withExtTenantIdRequest({
    url: '/soyoungzg/api/distributorBankCard/update',
    method: 'post',
    data,
  });
}

// 查询未关联信息
export function getlistByBankCardForApps(data) {
  return withExtTenantIdRequest({
    url: '/soyoungzg/api/distributorContractInfo/listByBankCardForApps',
    method: 'get',
    params: data,
  });
}

// 关联绑定收付款账号信息
export function bindContractInfo(data) {
  return withExtTenantIdRequest({
    url: '/soyoungzg/api/distributorBankCard/bindContractInfo',
    method: 'post',
    data,
  });
}

// 获取银行卡对象
export function distributorBankCardGet(data) {
  return withExtTenantIdRequest({
    url: '/soyoungzg/api/distributorBankCard/get',
    method: 'get',
    params: data,
  });
}